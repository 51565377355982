#body html, #body body {
    overflow: hidden;
  }
  
  .cookiebox {
    font-family: Montserrat;
  }
  
  .cookiebox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999999;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cookiebox-container {
    max-width: 600px;
    width: 100%;
    background: #2196F3;
    border-radius: 30px;
    padding: 30px;
  }
  
  .cookiebox-header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
  }
  
  .cookiebox-header img {
    width: 60px;
  }
  
  .cookiebox-header {
    font-size: 27px;
    color: white;
  }
  
  .cookiebox-body {
    margin-top: 15px;
  }
  
  .cookiebox-body p {
    font-size: 16px;
    line-height: 1.5;
    color: white;
    font-weight: 400;
  }
  
  .cookiebox-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .cookiebox-buttons button {
    padding: 20px;
    width: 47%;
    font-size: 16px;
    color: white;
  }
  
  #btnYes {
    background: #00e676;
  }
  
  #btnNo {
    background-color: #d50000;
  }
  