.konferenc__top {
    margin-top: 50px;
  }
  
  p.tx-17 {
    margin-top: 10px;
    line-height: 1.5;
  }
  
  h2.tx-17 {
    line-height: 1.5;
    font-weight: 700;
    font-size: 32px;
    margin-top: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 10px;
    color: #333;
  }
  
  input,
  textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  form button {
    padding: 10px;
    background-color: #ef9933;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #cc7f21;
  }
  
  #thanks-message {
    color: green;
    text-align: center;
    margin-top: 20px;
    font-size: 1.2em;
  }
  
  .hidden {
    display: none;
  }
  